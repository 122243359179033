import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export default function News() {
  return (
    <section id="news" className="spacey">
      <h2 className="upper centre">News</h2>

      <ol className="stories">
      <li className="story">
          <StaticImage as="figure" src="../images/gigs/MUSICwestport-2024-Weekend.jpg" alt="Gertrudes gig" objectFit="contain" className="border" />
          <article>
            <h3>Sat, Aug.17 @ 5PM</h3>
            <p>Lockwood Park Stage, Westport, Ontario</p>
            <p>By donation</p>
            <p><a class="button" href="https://coveinn.com/event/musicwestport-2023/">More Information</a> </p>
          </article>
        </li>
      </ol>
    </section>
  )
}
